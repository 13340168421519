import { createRef, useCallback, useContext, useMemo, useState } from "react";
import { Button, Col, Form, Modal, Row } from "react-bootstrap";
import { inputToUpper } from "../../utils.js";
import { AxiosContext } from "../../contexts/AxiosContext.js";
import { useParams } from "react-router";
import CampaignEventTable from "./CampaignEventTable.js";
import CampaignTable from "./CampaignTable.js";
import "./Campaigns.css";
import { CampaignContext } from "../../contexts/CamapaignContext.js";
import CoordinateInput from "../../components/CoordinateInput.js";

export default function Campaigns() {

    const { campaign_id } = useParams();

    const axiosContext = useContext(AxiosContext);
    const campaignsContext = useContext(CampaignContext);

    const [campaignToShow, _setCampaignToShow] = useState(parseInt(campaign_id) || null);
    const [showCampaignDialog, setShowCampaignDialog] = useState(false);
    const [showAddEventDialog, setShowAddEventDialog] = useState({'show': false, 'campaign': null});

    const setCampaignToShow = useCallback((show) => {
        let path = `/campaigns/${show}`;
        if (path !== window.location.pathname) {
            window.history.pushState(null, null, path);
        };
        _setCampaignToShow(show);
    }, [_setCampaignToShow]);

    const refreshCampaigns = useCallback(() => {
        campaignsContext.refresh();
    }, [campaignsContext]);

    ///////////////////////////////////////////////////////////////////////////
    // Add Campaign handling
    ///////////////////////////////////////////////////////////////////////////

    function addCampaign() {
        setShowCampaignDialog(true);
    }

    function handleAddCampaignClose() {
        setShowCampaignDialog(false);
    }

    function handleAddCampaignSave(event) {

        // Never want normal
        event.preventDefault();

        const form = event.currentTarget;
        if (form.checkValidity() === false) {
            event.stopPropagation();
            return
        }

        let dispatch = {}

        for (const [key, value] of (new FormData(form)).entries()) {
            if (value) {
                dispatch[key] = value;
            }
        }

        axiosContext.post(
            '/campaigns',
            dispatch,
        ).then((res) => {
            if (!res) return;
            setShowCampaignDialog(false);
            console.log("R1")
            campaignsContext.refresh();
        });
    }

    ///////////////////////////////////////////////////////////////////////////
    // Add Event handling
    ///////////////////////////////////////////////////////////////////////////

    const [eventFormState, setEventFormState] = useState({})
    const eventFormCoordinateRef = createRef();

    const updateEventFormState = useCallback((kv) => {
        let new_data = {...eventFormState, ...kv};
        setEventFormState(new_data);
    }, [eventFormState, setEventFormState]);

    const updateEventFormEntry = useCallback((evt) => {
        let element = evt.target;
        updateEventFormState({[element.name]: element.value})
    }, [updateEventFormState]);

    function handleAddEventClose() {
        setShowAddEventDialog({'show': false})
    }

    function handleAddEventSave(event, cell) {

        // Never want normal
        event.preventDefault();

        const form = event.currentTarget;
        if (form.checkValidity() === false) {
            event.stopPropagation();
            return
        }

        let dispatch = {...eventFormState};
        if (!dispatch.bullseye_loc) {
            eventFormCoordinateRef.current.setCustomValidity("Must have a valid bullseye coordiante");
            return;
        }

        // Ignore bullseye, as we only care about _loc
        delete (dispatch['bullseye'])

        axiosContext.post(
            `/campaigns/${showAddEventDialog.campaign.id}/events`,
            dispatch,
        ).then((res) => {
            if (!res) return;
            setShowAddEventDialog({'show': false});
            refreshCampaigns(showAddEventDialog.campaign.id);
        });
    }

    ///////////////////////////////////////////////////////////////////////////
    // Event Table Handling (/campaign_id)
    ///////////////////////////////////////////////////////////////////////////

    const campaign_data = useMemo(() => {
        for (const elem of campaignsContext.campaigns) {
            if (elem?.id === campaignToShow) {
                return elem;
            }
        }
        return {};
    }, [campaignsContext, campaignToShow]);

    // Show our tables
    return (
        <>
        <CampaignTable
            campaigns={campaignsContext.campaigns}
            setShowAddEventDialog={setShowAddEventDialog}
            setCampaignToShow={setCampaignToShow} />

        <Button onClick={addCampaign}>Add Campaign</Button>

        <Modal backdrop="static" show={showCampaignDialog} onHide={handleAddCampaignClose}>
            <Modal.Header closeButton>
                <Modal.Title>Add Campaign</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <Form id="addCampaignForm" onSubmit={handleAddCampaignSave}>
                    <Row className="mb-3">
                        <Form.Group as={Col} className="col-3">
                            <Form.Label>Tag</Form.Label>
                            <Form.Control
                                name="tag" required pattern='^[A-Z]{4}$' minLength="4" maxLength="4" onChange={inputToUpper} />
                        </Form.Group>
                        <Form.Group as={Col}>
                            <Form.Label>Campaign Name</Form.Label>
                            <Form.Control name="name" required minLength="4" />
                        </Form.Group>
                    </Row>
                    <Row className="mb-3">
                        <Form.Group as={Col}>
                            <Form.Label>Theatre</Form.Label>
                            <Form.Select name="theatre" required>
                                <option>Caucasus</option>
                                <option value="MarianaIslands">Mariana Islands</option>
                                <option>Nevada</option>
                                <option>Normandy</option>
                                <option value="PersianGulf">Persian Gulf</option>
                                <option value="TheChannel">The Channel</option>
                                <option>Syria</option>
                                <option>Kola</option>
                            </Form.Select>
                        </Form.Group>
                    </Row>

                    <Row className="mb-3">
                        <Form.Group as={Col}>
                            <Form.Label>Campaign Maintainer</Form.Label>
                            <Form.Control name="owner" required />
                        </Form.Group>
                    </Row>
                </Form>

            </Modal.Body>
            <Modal.Footer>
            <Button variant="secondary" onClick={handleAddCampaignClose}>
                Close
            </Button>
            <Button variant="primary" type="submit" form="addCampaignForm">
                Save Changes
            </Button>
            </Modal.Footer>
        </Modal>

        <Modal backdrop="static" show={showAddEventDialog.show} onHide={handleAddEventClose}>
            <Modal.Header closeButton>
                <Modal.Title>Add Event to {showAddEventDialog?.campaign?.tag}</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <Form id="addEventForm" action='' onSubmit={handleAddEventSave}>
                    <Row className="mb-3">
                        <Form.Group as={Col}>
                            <Form.Label>Event Date</Form.Label>
                            <Form.Control onChange={updateEventFormEntry} type="date" required name="date" />
                        </Form.Group>
                    </Row>
                    <Row className="mb-3">
                        <Form.Group as={Col}>
                            <Form.Label>ATO Day</Form.Label>
                            <Form.Control onChange={updateEventFormEntry} type="number" step="1" min="0" name="ato_day" required />
                        </Form.Group>
                    </Row>
                    <Row className="mb-3">
                        <Form.Group as={Col}>
                            <Form.Label>Event Bullseye</Form.Label>
                            <CoordinateInput
                                    updateFormState={updateEventFormState}
                                    value={eventFormState.bullseye || ""}
                                    required strict name="bullseye" />
                        </Form.Group>
                    </Row>
                </Form>

            </Modal.Body>
            <Modal.Footer>
            <Button variant="secondary" onClick={handleAddEventClose}>
                Close
            </Button>
            <Button variant="primary" type="submit" form="addEventForm">
                Add Event
            </Button>
            </Modal.Footer>
        </Modal>


        {campaignToShow && <CampaignEventTable campaign={campaign_data} />}
        </>
    );
}
